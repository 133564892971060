import React from "react";
import security from "../../../Icons/security.png";
import systemRequirements from "../../../Icons/systemRequirement.png";
import privacy from "../../../Icons/privacy.png";
import print from "../../../Icons/print.png";
import logOut from "../../../Icons/logout.png";

const Buttons = () => {
  return (
    <>
      <div className="button-section pt-3">
        <button className="common-button p-2 m-3">
          <img src={security} alt="" className="px-2" />
          Security
        </button>
        <button className="common-button p-2 m-3">
          <img src={systemRequirements} alt="" className="px-2" />
          System Requirements
        </button>
        <button className="common-button p-2 m-3">
          <img src={privacy} alt="" className="px-2" />
          Privacy
        </button>
        <button className="common-button p-2 m-3">
          <img src={print} alt="" className="px-2" />
          Print
        </button>
        <button className="common-button p-2 m-3">
          <img src={logOut} alt="" className="px-2" />
          Log Out
        </button>
      </div>
    </>
  );
};

export default Buttons;
