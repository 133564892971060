import React from "react";
import { Container, Form } from "react-bootstrap";
import Header from "../FirstLandingPage/Header/Header";
import Navigation from "../FirstLandingPage/Navigation/Navigation";
import "./FourthLandingPage.css";
import startAgain from "../../Icons/startAgain.png";
import modifyAnswer from "../../Icons/modifyAnswer.png";
import print from "../../Icons/print.png";
import help from "../../Icons/help.png";
import { Link } from "react-router-dom";

const FourthLandingPage = () => {
  return (
    <div>
      <Header />
      <Navigation />
      <Container>
        <div className="button-section py-3">
          <button className="common-button p-2 m-3">
            <img src={startAgain} alt="" className="px-2" />
            <span className="pe-2">Start Again</span>
          </button>
          <button className="common-button p-2 m-3">
            <img src={modifyAnswer} alt="" className="px-2" />
            <span className="pe-2">Modify Answer</span>
          </button>
          <button className="common-button p-2 m-3">
            <img src={print} alt="" className="px-2" />
            <span className="pe-2">Print</span>
          </button>
          <button className="common-button p-2 m-3">
            <img src={help} alt="" className="px-2" />
            <span className="pe-2">Help</span>
          </button>
          <div className="eligible-section py-3">
            <h2 className="eligible-title bottom-horizontal-row">
              Find out if you are eligible to apply.
            </h2>
            <div className="dropdown-selection py-3">
              {/* <Form.Control type="text" placeholder="please make a selection" /> */}
              <div className="py-3">
                <h5>
                  What is the purpose of visit?
                  <span className="field-required">*</span>
                </h5>
                <Form.Select required>
                  <option>please make a selection</option>
                  <option value="1">Medical Purpose</option>
                  <option value="2">Tour Purpose</option>
                  <option value="3">Political Purpose</option>
                </Form.Select>
              </div>
              <div className="pb-3">
                <h5>
                  How long do you plan to stay ?
                  <span className="field-required">*</span>
                </h5>
                <Form.Select required>
                  <option>please make a selection</option>
                  <option value="1">3 months</option>
                  <option value="2">2 months</option>
                  <option value="3">1 month</option>
                </Form.Select>
              </div>
              <div className="pb-3">
                <h5>
                  what is the country of nationality of your passport ?
                  <span className="field-required">*</span>
                </h5>
                <Form.Select required>
                  <option>please make a selection</option>
                  <option value="1">Australia</option>
                  <option value="2">Germany</option>
                  <option value="3">Turkey</option>
                </Form.Select>
              </div>
              <div className="pb-3">
                <h5>
                  what is your current country of residence ?
                  <span className="field-required">*</span>
                </h5>
                <Form.Select required>
                  <option>please make a selection</option>
                  <option value="1">Australia</option>
                  <option value="2">Germany</option>
                  <option value="3">Turkey</option>
                </Form.Select>
              </div>
              <h5>
                what is your date of birth ?
                <span className="field-required">*</span>
              </h5>
              <input type="date" name="date" className=" p-2"></input>
              {/* <Form.Select required>
                <option>please make a selection</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select> */}
            </div>
            <div className="next d-flex justify-content-center py-5">
              <Link to="/review">
                <button className="next-button py-2 px-5">Next</button>
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default FourthLandingPage;
