import React from "react";
import "./Header.css";
import About from "../../../Pages/MainPages/About/About";
import NavbarEvisa from "../NavbarEvisa/NavbarEvisa";
import NavigationEvisa from "../NavigationEvisa/NavigationEvisa";

const Header = () => {
  return (
    <div>
      <div className="background-image">
        {/* <NavbarEvisa /> */}
        <NavigationEvisa></NavigationEvisa>
        <div className="about-banner-title d-flex justify-content-center mt-4">
          <h1>About Us</h1>
        </div>
      </div>
      <About />
    </div>
  );
};

export default Header;
