import React from "react";
import { Link } from "react-router-dom";
import "./SecondLandingBanner.css";

const SecondLandingBanner = () => {
  return (
    <div className="container ps-4 second-banner-bg">
      <h4 className="second-login-header bottom-horizontal-row">
        Log In or Register
      </h4>
      {/* <hr className="bottom-horizontal-row second-align m-0" /> */}
      <p className="second-login-description mt-2 ">
        Welcome to the eVisa system
      </p>
      <p className="second-description py-5">
        You will need an user ID and a Password in order to securely conduct
        online transactions From this page can:
      </p>
      <div>
        <h4 className="register-information">
          Register for a new User ID and Password
        </h4>
        <hr className="second-align-register  m-0" />
      </div>
      <div className="pt-3">
        <h4 className="register-information">
          Log In with a existing User ID and Password
        </h4>
        <hr className="register-information-align  m-0" />
      </div>
      <p className="pt-4 register-full-description">
        If you already have an account use your User ID and Password below and
        select Log In.Once you have logged in.
        <br />
        d, change your recovery questions and answers{" "}
      </p>

      <div className="text-center my-5  contact-form-bg p-5">
        <input
          className=" contact-input p-2 border-0"
          type="text"
          placeholder="User ID"
        />
        <br />
        <br />
        <input
          className=" contact-input p-2 border-0"
          type="text"
          placeholder="Password"
        />
        <br />
        <br />
        <Link to="/terms-condition">
          <button className="contact-button p-2 border-0">LOG IN</button>
        </Link>
        {/* <button className=" contact-button p-2 border-0">Submit</button> */}
        {/* <div className='row '>
                    <div className='col-md-5'>
                        <h4 className='password-text'>Forgot your Passsword ?</h4>
                        <hr className='password-align  m-0' />
                    </div>
                    <div className='col-md-5'>
                        <h4 className='login-text'>Register a new account</h4>
                        <hr className='login-align m-0' />
                    </div>

                </div> */}
      </div>
    </div>
  );
};

export default SecondLandingBanner;
