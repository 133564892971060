import React from "react";
import NavbarEvisa from "../../../components/common/NavbarEvisa/NavbarEvisa";
// import homeBg from "../../../components/assets/images/homeBg.png";
import registrationSteps from "../../../components/assets/images/visa-reg-step-img.svg";
import "./Home.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import Cards from "../About/Cards/Cards";
import Footer from "../../../components/common/Footer/Footer";
import { Link } from "react-router-dom";
import NavigationEvisa from "../../../components/common/NavigationEvisa/NavigationEvisa";

const Home = () => {
  //   const homeBgImg = {
  //     backgroundImage: `url(${homeBg})`,
  //     minHeight: "380px",
  //     backgroundPosition: "center",
  //     backgroundRepeat: "no-repeat",
  //     backgroundSize: "cover",
  //     position: "relative",
  //   };
  return (
    <div>
      <div className="home-background-image">
        {/* <NavbarEvisa /> */}
        <NavigationEvisa></NavigationEvisa>
        <Container>
          <Row className="text-white">
            <Col md={6}>
              <div className="about-banner-title my-5">
                <h1>Welcome to Official e-Visa Portal of Bangladesh</h1>
              </div>
              <p className="mt-5 about-banner-details-txt">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Reprehenderit itaque ad ipsam et maxime ratione inventore, atque
                quo! Ad, cupiditate.
              </p>

              <Link to="/visitor-page">
                <button className="apply-now-button py-2 px-4 my-5">
                  Apply Now <FaArrowRight />
                </button>
              </Link>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-end"
            >
              <Link to="/visitor-page">
                <button className="vertical-button py-2 px-4 my-5">
                  Apply Now <FaArrowRight />
                </button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Registration Steps */}
      <div className="registration-steps">
        <Image src={registrationSteps} alt="" fluid className="" />
      </div>
      {/* Cards Section */}
      <Container className="my-5">
        <Cards />
      </Container>
      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default Home;
