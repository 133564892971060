import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import "./About.css";
import bannerImage from "../../../components/assets/images/banner-image.png";
import missionImage from "../../../components/assets/images/our-mission-image.png";
import missionBgImage from "../../../components/assets/images/our-mission-background-image.png";
import Footer from "../../../components/common/Footer/Footer";
import Cards from "./Cards/Cards";
import NavbarEvisa from "../../../components/common/NavbarEvisa/NavbarEvisa";

const About = () => {
  const missionBgImg = {
    backgroundImage: `linear-gradient(rgba(16, 22, 55, 0.94), rgba(16, 22, 55, 0.94)),url(${missionBgImage})`,
    minHeight: "380px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
  };
  return (
    <div>
      <Container>
        <div className="about-us-intro-description my-5 text-center">
          <p>
            Caters to the very need of enhancing both security and convenience
            Aims to bring about a significant improvement to the visa-processing scene in Bangladesh
            Provides a comprehensive visa application and processing system for governments and citizens
          </p>
          <p>
            <span className="quotation-style">
              Internationals from 80 cities around the world will be able to avail
              this visa processing services online. The inclusion of a greater
              number of countries is a progressive feat since, currently a
              limited number of countries have the access.
            </span>
          </p>
          <p>
            Allows consulates to provide all their services related to travel and immigration from a single point of entry
            Lets travelers to easily access all information relevant to the visa processing system, and lets them create, submit, and pay for visa applications on their own
            Information regarding any/all fees is clearly stated, and payment can be done from the gateway available online.
          </p>
        </div>

        {/* Our Mission Section Starts */}

        {/* Banner Image */}
        <div className="banner-image my-5">
          <Image src={bannerImage} fluid alt="" />
        </div>
        {/* Our Mission */}
        <div style={missionBgImg}>
          <Row className="d-flex justify-content-center align-items-center p-5 my-5">
            <Col md={6}>
              <Image src={missionImage} alt="" fluid />
            </Col>
            <Col md={6}>
              <section className="our-mission text-white">
                <div className="our-mission-title">
                  <h3>Our Mission.</h3>
                </div>
                <div className="our-mission-description">
                  <p>
                    Vitae auctor a nulla in feugiat ut integer et. Viverra
                    cursus at est imperdiet non vitae aliquet volutpat
                    fermentum. Purus donec sodales tempus egestas donec aliquam
                    euismod ut. Et egestas cursus sagittis adipiscing. Magna ut
                    orci dolor volutpat molestie felis, pharetra. Nunc tortor,
                    augue mattis lacus massa malesuada amet, eget. Vitae auctor
                    a nulla in feugiat ut integer et. Viverra cursus at est
                    imperdiet non vitae aliquet volutpat fermentum. Purus donec
                    sodales tempus egestas donec aliquam euismod ut. Et egestas
                    cursus sagittis adipiscing. Magna ut orci dolor volutpat
                    molestie felis, pharetra. Nunc tortor, augue mattis lacus
                    massa malesuada amet, eget.
                  </p>
                </div>
              </section>
            </Col>
          </Row>
        </div>

        {/* Our Mission Section Ends */}

        {/* About Bangladesh Section Starts */}

        <section className="about-bangladesh">
          <div className="about-bangladesh-title text-center mb-5">
            <h3>About Bangladesh.</h3>
          </div>
          <Row>
            <Col md={6}>
              <div className="about-bangladesh-intro-section">
                <div className="about-bangladesh-intro-title">
                  <h6>A Land of Breathtaking Beauty</h6>
                </div>
                <div className="about-bangladesh-intro-description">
                  <p>
                    Pakistan extends along both sides of the historic Indus
                    River, following its course from the mountain valleys of the
                    Himalayas down to the Arabian Sea. Bordering with India,
                    China, Afghanistan and Iran, it is strategically located
                    along the ancient trade routes between Asia and Europe.
                    Pakistan's territory includes a wide variety of landscapes,
                    from arid deserts, lush green valleys to stark mountain
                    peaks.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <ReactPlayer
                url="https://www.youtube.com/watch?v=QNUSIOMb6vI"
                muted={true}
                loop={true}
                controls={true}
                width="640"
              />
            </Col>
          </Row>
        </section>
        {/* About Bangladesh Section Ends */}
        <Cards />
        {/* Our Latest Updates Section Ends */}
      </Container>
      {/* Footer Starts */}
      <Footer />
      {/* Footer Ends */}
    </div>
  );
};

export default About;
