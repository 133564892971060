import React from 'react';
import Header from './Header/Header';
import Navigation from './Navigation/Navigation';
import Banner from './Banner/Banner';


const FirstLandingPage = () => {
    return (
        <div>
            <Header></Header>

            <Navigation></Navigation>
            <Banner></Banner>
        </div>
    );
};

export default FirstLandingPage;