import React from "react";
import "./ReveiwPage.css";
import { Container, Form } from "react-bootstrap";
import Header from "../FirstLandingPage/Header/Header";
import Navigation from "../FirstLandingPage/Navigation/Navigation";
import startAgain from "../../Icons/startAgain.png";
import print from "../../Icons/print.png";
import help from "../../Icons/help.png";
import edit from "../../Icons/modifyAnswer.png";
import { Link } from "react-router-dom";

const ReviewPage = () => {
  return (
    <div>
      <Header />
      <Navigation />
      <Container>
        <div className="button-section py-3">
          <button className="common-button p-2 m-3">
            <img src={startAgain} alt="" className="px-2" />
            <span className="pe-2">Start Again</span>
          </button>
          <button className="common-button p-2 m-3">
            <img src={print} alt="" className="px-2" />
            <span className="pe-2">Print</span>
          </button>
          <button className="common-button p-2 m-3">
            <img src={help} alt="" className="px-2" />
            <span className="pe-2">Help</span>
          </button>
        </div>
        <div className="review-section">
          <div className="d-flex justify-content-between">
            <div className="review-answer bottom-horizontal-row mb-2">
              <h2 className="review-title">Review Your Answers</h2>
            </div>
            <Link to="/eligible">
              <div className="review-edit-icon">
                <img src={edit} alt="" />
              </div>
            </Link>
          </div>
          {/* <h2 className="review-title bottom-horizontal-row">
            Review Your Answers
              <img src={edit} alt="" />
          </h2> */}
          <div className="edit-section">{/* <img src={edit} alt="" /> */}</div>
        </div>
        <div className="review-answer-section py-3">
          <div className="py-3">
            <h5>
              What is the purpose of visit?
              <Form.Control
                className="my-2"
                type="text"
                placeholder="Medical Purpose"
                aria-label="Disabled input example"
                disabled
                readOnly
              />
            </h5>
          </div>
          <div className="pb-3">
            <h5>
              How long do you plan to stay ?
              <Form.Control
                className="my-2"
                type="text"
                placeholder="3 months"
                aria-label="Disabled input example"
                disabled
                readOnly
              />
            </h5>
          </div>
          <div className="pb-3">
            <h5>
              what is the country of nationality of your passport ?
              <Form.Control
                className="my-2"
                type="text"
                placeholder="Australia"
                aria-label="Disabled input example"
                disabled
                readOnly
              />
            </h5>
          </div>
          <div className="pb-3">
            <h5>
              what is your current country of residence ?
              <Form.Control
                className="my-2"
                type="text"
                placeholder="Australia"
                aria-label="Disabled input example"
                disabled
                readOnly
              />
            </h5>
          </div>
          <h5>
            what is your date of birth ?
            <Form.Control
              className="my-2"
              type="text"
              placeholder="12-04-1995"
              aria-label="Disabled input example"
              disabled
              readOnly
            />
          </h5>
        </div>
        <div className="next d-flex justify-content-center py-5">
          <Link to="/welcome">
            <button className="next-button py-2 px-5">Next</button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default ReviewPage;
