import React from "react";
import Header from "../FirstLandingPage/Header/Header";
import SecondLandingNab from "../SecondLandingPage/SecondLandingNab/SecondLandingNab";
import "./ThirdLandingPage.css";
// import security from "../../Icons/security.png";
// import systemRequirements from "../../Icons/systemRequirement.png";
// import privacy from "../../Icons/privacy.png";
// import print from "../../Icons/print.png";
// import logOut from "../../Icons/logout.png";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Buttons from "./Buttons/Buttons";

const ThirdLandingPage = () => {
  return (
    <div>
      <Header />
      <SecondLandingNab />
      <Container>
        <Buttons />
        {/* <div className="button-section">
          <button className="common-button p-2 m-3">
            <img src={security} alt="" className="px-2" />
            Security
          </button>
          <button className="common-button p-2 m-3">
            <img src={systemRequirements} alt="" className="px-2" />
            System Requirements
          </button>
          <button className="common-button p-2 m-3">
            <img src={privacy} alt="" className="px-2" />
            Privacy
          </button>
          <button className="common-button p-2 m-3">
            <img src={print} alt="" className="px-2" />
            Print
          </button>
          <button className="common-button p-2 m-3">
            <img src={logOut} alt="" className="px-2" />
            Log Out
          </button>
        </div> */}
        <div className="terms-condition">
          <h2 className="terms-condition-title bottom-horizontal-row py-3">
            Terms and Conditions
          </h2>
          <div className="terms-condition-details py-3">
            <h5>
              By accessing the eVisa System, you are agreeing to abide by the
              following Terms and Conditions of User:
            </h5>
            <p>
              -You understand and accept that eVisa system is not responsible
              for any losses or damages incurred by anyone because of:
            </p>
            <h6>1. The use of the information available to the system.</h6>
            <h6>
              2. Any restrictions, delay, malfunction, or unavailable of the
              system.
            </h6>
            <p>
              -You understand and accept that eVisa system and applying online,
              we can communicate with you or your representative, if applicable
              via email.
            </p>
            <p>
              -All information provided by you is true and correct to the best
              of your knowledge.
            </p>
            <p>
              -To continue, choose “I Accept to indicate your acceptance of
              these Terms and Conditions. If you do not agree with these Terms
              and Conditions, choose “I DO Not Accept”. Note, you will not be
              able to access the system unless you accept the Terms and
              Conditions.
            </p>
          </div>
          <div className="acceptance-section d-flex justify-content-center mb-lg-5">
            <Link to="/eligible">
              <button className="text-uppercase py-2 px-5 mx-3 acceptance-button">
                I Accept
              </button>
            </Link>
            <button className="text-uppercase py-2 px-5 mx-3 rejection-button">
              I do not Accept
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ThirdLandingPage;
