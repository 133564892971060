import React from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { HiUser } from 'react-icons/hi';
import { ImSearch } from 'react-icons/im';
import { Link } from 'react-router-dom';
import logo from "../../assets/images/e-visa-logo.png";
import './NavigationEvisa.css';

const NavigationEvisa = () => {
    return (
        <Navbar collapseOnSelect expand="lg" bg="transparent" variant="dark">
            <Container>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className=''>
                    <Nav className='justify-content-between w-100'>
                        <Nav.Link href="#search"><ImSearch className=' fs-4 '></ImSearch></Nav.Link>
                        <Nav.Link as={Link} to="/home" className='nav-menu-title'>Home</Nav.Link>
                        <Nav.Link as={Link} to="/about" className='nav-menu-title'>About</Nav.Link>
                        <NavDropdown title="Fees" id="collasible-nav-dropdown" menuVariant="dark" className='nav-menu-title'>

                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>

                        </NavDropdown>
                    </Nav>
                    <Nav className='mx-auto px-5'>
                        <Navbar.Brand href="#home" className='m-0'>
                            <div>
                                <img src={logo} alt="" className="img-fluid" width={"150"} />
                            </div>
                        </Navbar.Brand>
                    </Nav>
                    <Nav className='justify-content-between w-100'>
                        <NavDropdown title="Support & Services" id="collasible-nav-dropdown" menuVariant="dark" className='nav-menu-title'>

                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>

                        </NavDropdown>
                        <Nav.Link href="#contact" className='nav-menu-title'>Contact</Nav.Link>
                        <Nav.Link href="#location" className='nav-menu-title'>Location</Nav.Link>
                        <Nav.Link href="#login" className='nav-menu-title'><HiUser className='border border-2 fs-4 rounded-circle'></HiUser></Nav.Link>


                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavigationEvisa;