import React from 'react';
import "./Header.css";

const Header = () => {
    return (
        <div className=''>
            <h1 className='header-title pt-5 py-3'>Bangladeshi eVisa</h1>
            <h5 className='header-description py-3'>A Gateway to Online Services for Visa and Immigration </h5>
        </div>
    );
};

export default Header;