import "./App.css";
import { Route, Routes } from "react-router";
import FirstLandingPage from "./Pages/FirstLandingPage/FirstLandingPage";
import SecondLandingPage from "./Pages/SecondLandingPage/SecondLandingPage";
import ThirdLandingPage from "./Pages/ThirdLandingPage/ThirdLandingPage";
import FourthLandingPage from "./Pages/FourthLandingPage/FourthLandingPage";
import ReviewPage from "./Pages/ReviewPage/ReviewPage";
import Welcome from "./Pages/Welcome/Welcome";
import Header from "./components/common/Header/Header";
import Home from "./Pages/MainPages/Home/Home";

function App() {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="about" element={<Header />} />
        <Route path="visitor-page" element={<FirstLandingPage />} />
        <Route path="login-page" element={<SecondLandingPage />} />
        <Route path="terms-condition" element={<ThirdLandingPage />} />
        <Route path="eligible" element={<FourthLandingPage />} />
        <Route path="review" element={<ReviewPage />} />
        <Route path="welcome" element={<Welcome />} />
      </Routes>
    </div>
  );
}

export default App;
