import React from "react";
import { GoChevronRight } from "react-icons/go";
import { FaArrowRight } from "react-icons/fa";
import "./Banner.css";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <div className="container my-5">
      <div className="row ps-3 row row-cols-1 row-cols-md-2 row-cols-lg-2 g-4">
        <div className="col-12 col-sm-12 col-md-6 col-lg-7 question-part">
          <h6 className="banner-header pb-3">
            Home <GoChevronRight /> All Services <GoChevronRight />
            Immigration & citizenship <GoChevronRight /> My Application
          </h6>

          <h5 className=" banner-description bottom-horizontal-row">
            Apply online for a visa , study or work permit
          </h5>
          {/* <hr className="m-0 description-align  " /> */}

          <div className="d-flex align-items-center pt-5">
            <div>
              <h4 className="banner-apply-header pe-2">
                What are you applying for
              </h4>
            </div>
            <div>
              <div class="dropdown">
                <button
                  className=" text-bg text-white btn  dropdown-toggle visitor-visa-button"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Visitor Visa
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="question-bg  mt-4 pe-4 ">
            <div className="">
              <h3 className="text-color user-question-title pb-3">
                Frequently asked questions-
              </h3>
              <p className="  user-question-description  ">
                Which visa applications are currently available online?
              </p>
              <hr className="m-0 description-question-align  " />
              <p className="  user-question-description pt-2 ">
                Can I send a new document after I submit my online visa
                appication?
              </p>
              <hr className="m-0 description-question-alignment  " />
              <p className=" user-question-description pt-2  ">
                How can i change and review my answers in my online application?
              </p>
              <hr className="m-0 description-question-aligns  " />
            </div>
            <h5 className="searchs-text pt-5 pb-2 text-color">
              What else do you need help with?
            </h5>

            <div>
              <input className="  border-0   search-text" type="text" />
              <button className="mx-2 input-button border-0  text-white ">
                Search
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-5 col-lg-4 ms-4 login-form p-5">
          <h2 className="register-header text-color">
            {" "}
            Already Have an account?
          </h2>
          <p className="text-description  pt-2">
            If you already have an account start your online application there
          </p>
          <Link to="/login-page">
            <button className="register-button p-2 my-5 text-white">
              LOG IN
            </button>
          </Link>
          <h2 className="account-header">Dont have an account ?</h2>
          <p className="account-description pt-2">
            Please create an account by registering
          </p>
          <button className="register-button p-2 my-5 text-white">
            REGISTER
          </button>
          <div>
            <p className="login-facility">
              <FaArrowRight className="  react-icons p-1 me-2" /> Safe, fast ,
              reliable
            </p>
            <p>
              <FaArrowRight className="react-icons p-1 me-2 my-2" /> Less paper
              work and waiting times
            </p>
            <p>
              <FaArrowRight className="react-icons p-1 me-2" /> Secure online
              payment
            </p>
            <p>
              <FaArrowRight className="react-icons p-1 me-2 my-2" /> Save your
              application for later retrieval
            </p>
            <p>
              <FaArrowRight className="react-icons p-1 me-2" /> Notify authority
              about changes
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
