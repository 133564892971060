import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import "./Welcome.css";
import Header from "../FirstLandingPage/Header/Header";
import SecondLandingNab from "../SecondLandingPage/SecondLandingNab/SecondLandingNab";
import Buttons from "../ThirdLandingPage/Buttons/Buttons";

const Welcome = () => {
  return (
    <div>
      <Header />
      <SecondLandingNab />
      <Container>
        <Buttons />
        <div className="welcome-title-section">
          <h2 className="welcome-title bottom-horizontal-row">
            Welcome, John Doe.
          </h2>
          <div className="welcome-intro-speech">
            <p>
              Make sure you sign out and close your browser when you are done
              your session today.This will help prevent unauthorized access to
              your account.
            </p>
          </div>
          <div className="welcome-note">
            <h6>
              Note : you can not use another person's account to submit an
              application , even a family member or close friend.You must use
              your own account.
            </h6>
          </div>
        </div>
        {/* Notification Information Card Section */}
        <Row className="my-4">
          <Col md={6} className="form-background">
            <div className="notification-information-title">
              <h6 className="title">Notification Information</h6>
            </div>
            <p>
              Complete the following information so we can send you email if
              there is an update to your application or message in your account.
            </p>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Email address
                  <span className="field-required">*</span>
                </Form.Label>
                <Form.Control type="email" placeholder="name@example.com" />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>
                  Preferred Language of notification
                  <span className="field-required">*</span>
                </Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Open this select menu</option>
                  <option value="1">English</option>
                  <option value="2">French</option>
                  <option value="3">Deutsch</option>
                </Form.Select>
              </Form.Group>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  value="option1"
                />
                <label class="form-check-label" for="inlineCheckbox1">
                  Remember me
                </label>
              </div>
            </Form>
            <div className="d-flex justify-content-center pb-2">
              <button className="button-style my-2">Update</button>
            </div>
          </Col>
          <Col md={6} className="form-background">
            <div className="notification-information-title">
              <h6 className="title">New Visa Application</h6>
            </div>
            <Row className="my-3">
              <Col md={4} className="button-style mx-3 px-5">
                Visitor Visa
              </Col>
              <Col md={4} className="button-style mx-3 px-5">
                Residency
              </Col>
            </Row>
            <Row>
              <Col md={4} className="button-style mx-3 px-5">
                Student Visa
              </Col>
              <Col md={4} className="button-style mx-3 px-5">
                Work Permit
              </Col>
            </Row>
            <div className="application-steps my-5">
              <p>
                <FaArrowRight className="  react-icons p-1 me-2" />
                Complete Saved Application
              </p>
              <p>
                <FaArrowRight className="react-icons p-1 me-2 my-2" />
                View Submitted Application
              </p>
              <p>
                <FaArrowRight className="react-icons p-1 me-2" /> Link an
                application to this account
              </p>
              <p>
                <FaArrowRight className="react-icons p-1 me-2 my-2" />
                View account message
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Welcome;
