import React from "react";
import "./Cards.css";
import { Card, Col, Row } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import LatestNewsCard from "./LatestNewsCard/LatestNewsCard";
import { Link } from "react-router-dom";
import cardImage1 from "../../../../components/assets/images/1.png";
import cardImage2 from "../../../../components/assets/images/2.png";
import cardImage3 from "../../../../components/assets/images/3.png";
import cardImage4 from "../../../../components/assets/images/4.png";
import cardImage5 from "../../../../components/assets/images/5.png";
import cardImage6 from "../../../../components/assets/images/6.png";


const Cards = () => {
  return (
    <div className="container-fluid pb-5 pt-3">
      <section className="our-latest-updates ">
        <div className="latest-update-title text-center my-5">
          <h3>Our Latest Updates.</h3>
          <p>
            See All Updates <FaArrowRight className="ms-2" />
          </p>
        </div>
      </section>
      {/* <Row xs={1} md={3} className="g-4 my-5">
// <Col>
        //     <Card>
        //         <Card.Img variant="top" src={cardImage} alt="" />
        //         <Card.Body>
        //             <Card.Title> Lorem ipsum dolor sit amet. </Card.Title>
        //             <Card.Text>
        //                 Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut,
        //                 facilis. Aliquam aut repellat omnis harum quia inventore quas
        //                 sit aperiam iure eligendi itaque aliquid, libero nihil
        //                 reprehenderit eveniet quos assumenda.
        //             </Card.Text>
        //         </Card.Body>
        //     </Card>
        // </Col>
        <LatestNewsCard></LatestNewsCard>
        <LatestNewsCard></LatestNewsCard>
        <LatestNewsCard></LatestNewsCard>
        <LatestNewsCard></LatestNewsCard>
        <LatestNewsCard></LatestNewsCard>
        <LatestNewsCard></LatestNewsCard>
      </Row> */}

      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
        {/* <LatestNewsCard></LatestNewsCard>
        <LatestNewsCard></LatestNewsCard>
        <LatestNewsCard></LatestNewsCard>
        <LatestNewsCard></LatestNewsCard>
        <LatestNewsCard></LatestNewsCard>
        <LatestNewsCard></LatestNewsCard> */}
        <div class="col">
          <div class="latest-news-card h-100">
            <img src={cardImage1} class="img-fluid" alt="..." />
            <div class="card-body">
              <h5 class="latest-news-card-title py-4">Ut est mauris mauris nisl quam.</h5>
              <p class="latest-news-card-details-txt pb-3">Arcu magna vulputate varius dui quam. Quis tincidunt ut scelerisque hendrerit est, neque. In commodo.</p>
              <Link to="/" className='read-more-txt'>Read More...</Link>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="latest-news-card h-100">
            <img src={cardImage2} class="img-fluid" alt="..." />
            <div class="card-body">
              <h5 class="latest-news-card-title py-4">Ut est mauris mauris nisl quam.</h5>
              <p class="latest-news-card-details-txt pb-3">Arcu magna vulputate varius dui quam. Quis tincidunt ut scelerisque hendrerit est, neque. In commodo.</p>
              <Link to="/" className='read-more-txt'>Read More...</Link>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="latest-news-card h-100">
            <img src={cardImage3} class="img-fluid" alt="..." />
            <div class="card-body">
              <h5 class="latest-news-card-title py-4">Ut est mauris mauris nisl quam.</h5>
              <p class="latest-news-card-details-txt pb-3">Arcu magna vulputate varius dui quam. Quis tincidunt ut scelerisque hendrerit est, neque. In commodo.</p>
              <Link to="/" className='read-more-txt'>Read More...</Link>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="latest-news-card h-100">
            <img src={cardImage4} class="img-fluid" alt="..." />
            <div class="card-body">
              <h5 class="latest-news-card-title py-4">Ut est mauris mauris nisl quam.</h5>
              <p class="latest-news-card-details-txt pb-3">Arcu magna vulputate varius dui quam. Quis tincidunt ut scelerisque hendrerit est, neque. In commodo.</p>
              <Link to="/" className='read-more-txt'>Read More...</Link>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="latest-news-card h-100">
            <img src={cardImage5} class="img-fluid" alt="..." />
            <div class="card-body">
              <h5 class="latest-news-card-title py-4">Ut est mauris mauris nisl quam.</h5>
              <p class="latest-news-card-details-txt pb-3">Arcu magna vulputate varius dui quam. Quis tincidunt ut scelerisque hendrerit est, neque. In commodo.</p>
              <Link to="/" className='read-more-txt'>Read More...</Link>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="latest-news-card h-100">
            <img src={cardImage6} class="img-fluid" alt="..." />
            <div class="card-body">
              <h5 class="latest-news-card-title py-4">Ut est mauris mauris nisl quam.</h5>
              <p class="latest-news-card-details-txt pb-3">Arcu magna vulputate varius dui quam. Quis tincidunt ut scelerisque hendrerit est, neque. In commodo.</p>
              <Link to="/" className='read-more-txt'>Read More...</Link>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Cards;
