import React from 'react';
import Header from '../FirstLandingPage/Header/Header';
import SecondLandingBanner from './SecondLandingBanner/SecondLandingBanner';
import SecondLandingNab from './SecondLandingNab/SecondLandingNab';

const SecondLandingPage = () => {
    return (
        <div>
            <Header></Header>
            <SecondLandingNab></SecondLandingNab>
            <br />
            <SecondLandingBanner></SecondLandingBanner>
        </div>
    );
};

export default SecondLandingPage;
