import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BiEnvelope } from "react-icons/bi";
import { GoLocation } from "react-icons/go";
import { FiPhoneCall } from "react-icons/fi";
import { GiSmartphone } from "react-icons/gi";
import "./Footer.css";
import footerBgImage from "../../../components/assets/images/footer-bg.png";

const Footer = () => {
  const footerBgImg = {
    backgroundImage: `linear-gradient(rgba(16, 22, 55, 0.94), rgba(16, 22, 55, 0.94)),url(${footerBgImage})`,
    minHeight: "380px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
  };
  return (
    <div>
      <div
        style={footerBgImg}
        className="text-white d-flex align-items-center justify-content-center"
      >
        <Container>
          {/* <Row className="border-bottom-style py-3">
            <Col md={4}>
              <div className="footer-title">Bangladeshi eVISA SYSTEM</div>
            </Col>
            <Col md={1}></Col>
            <Col md={4}>
              <div className="footer-title">Contact</div>
            </Col>
            <Col md={1}></Col>
            <Col md={2}>
              <div className="footer-title">Call Us</div>
            </Col>
          </Row> */}
          <Row>
            <Col md={4}>
              <div className="footer-title border-bottom py-2">
                Bangladeshi e-Visa Solution
              </div>
              <div className="footer-description my-3">
                This is the e-Visa portal of Republic of Bangladesh. This will help you submit your visa application online, from the comfort of your home. Apply online, pay the fees and get your Visa in 30 minutes.
              </div>
              <p>© Techsist Ltd. All rights reserved.</p>
            </Col>
            <Col md={1}></Col>
            <Col md={4}>
              <div className="footer-title border-bottom py-2">Contact</div>
              <div className="contact-information">
                <div className="icons">
                  <div className="envelope-icon my-3">
                    <BiEnvelope className="me-2" />
                    info@evisa .com.bd
                  </div>
                  <div className="location-icon my-3">
                    <GoLocation className="me-2" />
                    Level-4, House-546/2, Lane-13, Baridhara DOHS, Dhaka-1206, Bangladesh
                  </div>
                </div>
              </div>
            </Col>
            <Col md={1}></Col>
            <Col md={2}>
              <div className="footer-title border-bottom py-2">Call Us</div>
              <div className="call-us">
                <div className="call-us-icon my-3">
                  <FiPhoneCall className="me-2" />
                  +88 02 58070308
                </div>
                <div className="mobile-icon my-3">
                  <GiSmartphone className="me-2" />
                  +880 1780 003 33
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
